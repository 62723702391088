@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

.chakra-checkbox__control {
  margin-top: 4px !important;
}

.chakra-collapse {
    padding-left: 2px;
}

option {
  color: black;
}

*:has(> .tags-input) {
  width: 90% !important;
}

.tags-input {
  width: auto !important;
  min-width: 0 !important;
}





.diff td pre, .diff td pre * {
    line-height: 95% !important;
    font-size: 90% !important;
}




.rti--tag {
    background: transparent !important;
    border: 1px solid #9998;
    padding-left: 14px !important;
    padding-top: 3px !important;
    color: inherit !important;
}
.rti--tag button {
    /*height: 20px;*/
    padding: 0 !important;
    padding-top: 1px !important;
    padding-left: 12px !important;
    padding-right: 10px !important;
    /*padding: 4px !important;*/
}

.rti--input {
  background: transparent !important;
}

.rti--container {
  border-color: #9996 !important;
  background: transparent !important;
  color: inherit !important;
}

.chakra-input {
  background: transparent !important;
  color: inherit !important;
}